import React from "react";
import { graphql } from "gatsby";
import PageLayout from "@components/PageLayout/PageLayout";
import Typography from "@components/Typography/Typography";
import { getTableOfContents, processCmsContent } from "@helpers/CmsProcessing";
import * as styles from "./../CommonPostStyles.module.scss";
import TableOfContents from "../components/TableOfContents";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import CmsArticleBody from "../components/CmsArticleBody";
import RelatedPosts from "../components/RelatedPosts";
import { useTrackSidebar } from "@helpers/Hooks";
import CmsFeaturedImage from "../components/CmsFeaturedImage/CmsFeaturedImage";
import {
	BlogNode,
	blogNodeToCmsNode,
	filterBlogCategoriesForEnv,
} from "../CmsTypes";
import BlogsHero from "pages/blog/_layout/BlogsHero";
import BlogRollV2 from "@components/BlogRollV2";

type CMSBlogTemplateProps = {
	data: {
		cmsBlog: BlogNode & { body: { processed: string } };
		relatedBlogs: { nodes: BlogNode[] };
	};
};

export default function BlogTemplate({
	data: { cmsBlog, relatedBlogs }, // this prop will be injected by the GraphQL query below.
}: CMSBlogTemplateProps) {
	const article_body_elements = processCmsContent(cmsBlog.body.processed);
	const TOCs = getTableOfContents(article_body_elements);
	useTrackSidebar(
		article_body_elements
			.filter((node: any) => node !== null && node.type === "h4")
			.map((toc: any) => toc.props.id),
		"ui-01",
		"cloudy-blue"
	);
	return (
		<PageLayout
			seoOptions={{
				type: "BLOG",
				data: {
					title: cmsBlog.title,
					description: cmsBlog.body.summary,
					article: true,
				},
			}}
		>
			<BlogsHero firstBlog={cmsBlog} showReadMoreButton={false} showFullTitle />
			<div className={styles.content}>
				<CmsArticleBody
					theme="light"
					contents={article_body_elements}
				/>
			</div>
			<BlogRollV2
				blogs={relatedBlogs.nodes.map(blogNodeToCmsNode).sort(() => Math.random() - 0.5)}
				postsOnTablet={2}
			/>
		</PageLayout>
	);
}

export const pageQuery = graphql`
	query ($BlogId: String!) {
		cmsBlog: nodeBlog(id: { eq: $BlogId }) {
			id
			title
			path {
				alias
			}
			body {
				processed
				summary
			}
			created(formatString: "MMMM DD, YYYY")
			relationships {
				field_blog_feature_image {
					field_media_image {
						alt
					}
					relationships {
						field_media_image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 808)
								}
							}
						}
					}
				}
			}
		}
		relatedBlogs: allNodeBlog(  sort: {order: DESC, fields: created}
			filter: {id: {ne: $BlogId}}
			limit: 10) {
			nodes {
				id
				title
				path {
					alias
				}
				created(formatString: "MMMM DD, YYYY")
				body {
					summary
				}
				relationships {
					field_blog_category {
						name
					}
					field_blog_feature_image {
						relationships {
							field_media_image {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 808)
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
