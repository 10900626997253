import React, { useEffect, useRef, useState } from "react";
import { CmsNode } from "@components/CMS/CmsTypes";
import CmsFeaturedImage from "@components/CMS/components/CmsFeaturedImage/CmsFeaturedImage";
import { useWindowSizeType } from "@helpers/Hooks";
import { Link, PageMargins, TypographyV2 } from "..";
import * as styles from "./index.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { ReadMoreButtonV2 } from "@components/Button_new/Button";
import classNames from "classnames";
import { ESSENTIAL_FEATURES } from "../../pages/pricing/data/_PricingData";

type BlogRollProps = {
	blogs: CmsNode[];
	postsOnTablet?: number;
	postsOnDekstopAndMobile?: number;
};

export default function BlogRollV2({
	blogs,
	postsOnTablet = 4,
	postsOnDekstopAndMobile = 3,
}: BlogRollProps): JSX.Element {
	const windowSizeType = useWindowSizeType();

	const isTablet =
		windowSizeType === "tablet" || windowSizeType === "tablet_small";

	const nBlogs = isTablet ? postsOnTablet : postsOnDekstopAndMobile;

	return (
		<>
			<div className={styles.blogCards}>
				{blogs.slice(0, nBlogs).map((node, index) => (
					<BlogCard key={`blog-card-${index}`} data={node} />
				))}
			</div>
			<ReadMoreButtonV2
				style={{
					marginBottom: 48,
				}}
			/>
		</>
	);
}

type BlogCardProps = {
	data: CmsNode;
};

export function BlogCard({ data }: BlogCardProps): JSX.Element {
	const {
		created,
		title,
		path,
		body: { summary },
	} = data;

	const titleRef = useRef<HTMLDivElement | null>(null);
	const [clampLines, setClampLines] = useState(2);

	useEffect(() => {
		if (titleRef.current) {
			if (titleRef.current.offsetHeight > 28) {
				setClampLines(2);
			} else {
				setClampLines(3);
			}
		}
	}, [titleRef]);

	return (
		<Link to={path.alias} internal={false}>
			<div className={styles.cardContainer}>
				<CmsFeaturedImage className={styles.image} node={data} />
				<div className={styles.titleSummaryAvatarAuthorDate}>
					<div className={styles.titleSummary}>
						<div className={styles.titleAndIcon}>
							<div ref={titleRef}>
								<TypographyV2
									variant="BODY_TEXT_LARGE"
									weight="MEDIUM"
									className={styles.title}
								>
									{title}
								</TypographyV2>
							</div>
							<div className={styles.icon}>
								<StaticImage
									loading="eager"
									src="../../assets/icons/arrow-up-right.svg"
									alt="down up right"
									width={24}
									height={24}
								/>
							</div>
						</div>
						<TypographyV2
							color="brand-subtle"
							variant="BODY_TEXT_MEDIUM"
							weight="REGULAR"
							className={classNames(styles.summary, {
								[styles.threeLines]: clampLines === 3,
							})}
						>
							{summary}
						</TypographyV2>
						<TypographyV2
							variant="BODY_TEXT_SMALL"
							weight="REGULAR"
							tabletVariant="BODY_TEXT_EXTRA_SMALL"
							mobileVariant="BODY_TEXT_EXTRA_SMALL"
						>
							{created}
						</TypographyV2>
					</div>
				</div>
			</div>
		</Link>
	);
}
