// extracted by mini-css-extract-plugin
export var avatar = "index-module--avatar--9vccg";
export var avatarAuthorDate = "index-module--avatarAuthorDate--Mw0h2";
export var blogCards = "index-module--blogCards--dCiBh";
export var blogRollBackground = "index-module--blogRollBackground--vlGNG";
export var cardContainer = "index-module--cardContainer--Db8i2";
export var icon = "index-module--icon--H+4TY";
export var image = "index-module--image--ePw1G";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--wWVkT";
export var readMoreBlogs = "index-module--readMoreBlogs--awAEC";
export var summary = "index-module--summary--zOi1o";
export var threeLines = "index-module--threeLines--4weXr";
export var title = "index-module--title--PH1RC";
export var titleAndIcon = "index-module--titleAndIcon--dfa-W";
export var titleSummary = "index-module--titleSummary--tRKAV";
export var titleSummaryAvatarAuthorDate = "index-module--titleSummaryAvatarAuthorDate--uymkY";